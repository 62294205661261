import { useEffect } from 'preact/hooks';
import toast, { Toaster as ReactHotToastToaster } from 'react-hot-toast';

import { consumePendingToast } from '../../helpers/toast';

export function Toaster() {
  useEffect(() => {
    const pendingToast = consumePendingToast();

    pendingToast.forEach(({ message, type }) => {
      if (type === 'success') {
        toast.success(message);
      }
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ReactHotToastToaster />;
}
